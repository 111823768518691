/* Global Styles */
html, body {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  -webkit-font-smoothing: antialiased; /* Improve text rendering for Webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* Improve text rendering for macOS */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw; /* Prevent overflow */
  overflow-y: auto;
  position: relative;
}

body {
  perspective: 3px; /* Increase perspective for more depth */
}

*, *::before, *::after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100vw; /* Prevent overflow */
}

.App-logo {
  height: 20vmin; /* Reduce the logo size for higher resolution */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes pulse {
    0%, 100% { transform: scale(0.85); }
    60% { transform: scale(1); }
  }
  .App-logo {
    animation: pulse 5s infinite;
  }
}

/* Parallax Effect */
section {
  transform-style: preserve-3d;
  width: 100%;
  max-width: 100vw; /* Prevent overflow */
  box-sizing: border-box;
  overflow: hidden;
}

#hero {
  transform: translateZ(-1px) scale(1.25); /* Adjust scale for better visual clarity */
  background-attachment: fixed;
  width: 100%;
  max-width: 100vw; /* Prevent overflow */
  box-sizing: border-box;
  overflow: hidden;
}

#about, #contact {
  transform: translateZ(0);
  padding: 2rem; /* Add padding to keep text within view and not distorted */
  text-align: center; /* Center align the text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw; /* Prevent overflow */
}

/* Animations for Scroll */
section {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

/* Responsive Container Adjustments */
.container {
  width: 100%;
  max-width: 1200px; /* Limit maximum width */
  margin: 0 auto; /* Keep everything centered */
  padding: 0 1rem; /* Add padding to avoid elements touching edges */
}

@media (max-width: 768px) {
  .container {
    padding: 0 0.5rem; /* Reduce padding on smaller screens */
  }

  #hero {
    transform: none; /* Disable parallax effect on small screens */
    background-attachment: scroll; /* Change attachment for a simpler effect */
  }

  section {
    transform: none; /* Remove transformation */
  }
}

/* Navbar Styles */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 100;
  padding: 1rem;
}

nav .menu {
  width: 100%;
  max-width: 100vw; /* Prevent overflow */
}

nav .menu-item {
  padding: 1rem 0; /* Add padding to menu items */
}

/* Footer Scroll Arrow */
.scroll-arrow {
  position: absolute;
  bottom: 2rem;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* link styles */
.link-style {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  position: relative;
  transition: all 0.3s ease;
}

.link-style::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: currentColor;
  left: 0;
  bottom: -2px;
  transition: width 0.3s ease;
}

.link-style:hover::after {
  width: 100%;
}

.link-style:hover {
  transform: translateY(-2px);
}

/* Menu Overlay for Mobile */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* Menu opened state adjustments */
.menu-open {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
  nav {
    padding: 0.5rem; /* Reduce padding for mobile */
  }

  .scroll-arrow svg {
    width: 3rem;
    height: 3rem;
  }
}
